import React, { Component } from 'react';

import './Footer.css'

class Footer extends Component {
  render(){
    return(
      <div className="footer">
          <p>&copy; Inc. all rights reserved 2022 | Lukas Van Hulle | Designed by Viktor Van Hulle</p>
      </div>
    )
  }
}

export default Footer