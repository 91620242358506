import Cities1 from './Cities_images/850_9076.jpg';
import Cities2 from './Cities_images/lukas-vanhulle foto 1 bewerkt.jpg';
import Cities4 from './Cities_images/lukas-vanhulle foto 2 bewerkt.jpg';
import Cities5 from './Cities_images/lukas_vanhulle-nachtfotografie-1.jpg';
import Cities6 from './Cities_images/lukas_vanhulle-nachtfotografie-2.jpg';
import Cities7 from './Cities_images/lukas_vanhulle-nachtfotografie-3.jpg';
import Cities8 from './Cities_images/P1030098.JPG';
import Cities9 from './Cities_images/P1030503 - kopie.JPG';
import Cities10 from './Cities_images/P1030594.JPG';
import Cities11 from './Cities_images/P1090792.JPG';
import Cities12 from './Cities_images/P1100892.jpg';

//MOBILE
import Cities1_mobile from './Cities_images_mobile/850_9076.jpg';
import Cities2_mobile from './Cities_images_mobile/lukas-vanhulle foto 1 bewerkt.jpg';
import Cities4_mobile from './Cities_images_mobile/lukas-vanhulle foto 2 bewerkt.jpg';
import Cities5_mobile from './Cities_images_mobile/lukas_vanhulle-nachtfotografie-1.jpg';
import Cities6_mobile from './Cities_images_mobile/lukas_vanhulle-nachtfotografie-2.jpg';
import Cities7_mobile from './Cities_images_mobile/lukas_vanhulle-nachtfotografie-3.jpg';
import Cities8_mobile from './Cities_images_mobile/P1030098.JPG';
import Cities9_mobile from './Cities_images_mobile/P1030503 - kopie.JPG';
import Cities10_mobile from './Cities_images_mobile/P1030594.JPG';
import Cities11_mobile from './Cities_images_mobile/P1090792.JPG';
import Cities12_mobile from './Cities_images_mobile/P1100892.jpg';

export const CitiesImages_mobile = [ 
  {
    title: 'Cities-image',
    image: Cities1_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'Cities-image',
    image: Cities2_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'Cities-image',
    image: Cities4_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities5_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities6_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities7_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities8_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities9_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities10_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities11_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities12_mobile,
    cName: 'sm-image-category'

  },
]

export const CitiesImages = [ 
  {
    title: 'Cities-image',
    image: Cities1,
    cName: 'sm-image-category',
  },
  {
    title: 'Cities-image',
    image: Cities2,
    cName: 'sm-image-category',
  },
  {
    title: 'Cities-image',
    image: Cities4,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities5,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities6,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities7,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities8,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities9,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities10,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities11,
    cName: 'sm-image-category'

  },
  {
    title: 'Cities-image',
    image: Cities12,
    cName: 'sm-image-category'

  },
]





