import Wildlife1 from './wildlife_images/850_0421.jpg';
import Wildlife2 from './wildlife_images/850_1388.jpg';
import Wildlife3 from './wildlife_images/850_1544.jpg';
import Wildlife4 from './wildlife_images/850_1603.jpg';
import Wildlife5 from './wildlife_images/850_2311.jpg';
import Wildlife6 from './wildlife_images/850_2350.jpg';
import Wildlife7 from './wildlife_images/850_2645.jpg';
import Wildlife8 from './wildlife_images/850_2671.jpg';
import Wildlife10 from './wildlife_images/850_2893.jpg';
import Wildlife11 from './wildlife_images/850_2919.jpg';
import Wildlife12 from './wildlife_images/850_2944.jpg';
import Wildlife13 from './wildlife_images/850_3043.jpg';
import Wildlife14 from './wildlife_images/850_3093.jpg';
import Wildlife15 from './wildlife_images/850_6233.jpg';
import Wildlife16 from './wildlife_images/850_6336.jpg';
import Wildlife17 from './wildlife_images/850_6411.jpg';
import Wildlife18 from './wildlife_images/850_6428.jpg';
import Wildlife19 from './wildlife_images/850_6556.jpg';
import Wildlife20 from './wildlife_images/850_7549.jpg';
import Wildlife21 from './wildlife_images/850_7552.jpg';
import Wildlife22 from './wildlife_images/850_7706.jpg';
import Wildlife23 from './wildlife_images/850_7723.jpg';
import Wildlife24 from './wildlife_images/850_7727.jpg';
import Wildlife25 from './wildlife_images/850_7736.jpg';
import Wildlife26 from './wildlife_images/850_7755.jpg';
import Wildlife27 from './wildlife_images/850_7805.jpg';
import Wildlife28 from './wildlife_images/850_7853.jpg';
import Wildlife29 from './wildlife_images/850_8846.jpg';
import Wildlife30 from './wildlife_images/850_9810.jpg';
import Wildlife31 from './wildlife_images/P1040747.JPG';
import Wildlife32 from './wildlife_images/P1040784.JPG';

// MOBILE
import Wildlife1_mobile from './wildlife_images_mobile/850_0421.jpg';
import Wildlife2_mobile from './wildlife_images_mobile/850_1388.jpg';
import Wildlife3_mobile from './wildlife_images_mobile/850_1544.jpg';
import Wildlife4_mobile from './wildlife_images_mobile/850_1603.jpg';
import Wildlife5_mobile from './wildlife_images_mobile/850_2311.jpg';
import Wildlife6_mobile from './wildlife_images_mobile/850_2350.jpg';
import Wildlife7_mobile from './wildlife_images_mobile/850_2645.jpg';
import Wildlife8_mobile from './wildlife_images_mobile/850_2671.jpg';
import Wildlife10_mobile from './wildlife_images_mobile/850_2893.jpg';
import Wildlife11_mobile from './wildlife_images_mobile/850_2919.jpg';
import Wildlife12_mobile from './wildlife_images_mobile/850_2944.jpg';
import Wildlife13_mobile from './wildlife_images_mobile/850_3043.jpg';
import Wildlife14_mobile from './wildlife_images_mobile/850_3093.jpg';
import Wildlife15_mobile from './wildlife_images_mobile/850_6233.jpg';
import Wildlife16_mobile from './wildlife_images_mobile/850_6336.jpg';
import Wildlife17_mobile from './wildlife_images_mobile/850_6411.jpg';
import Wildlife18_mobile from './wildlife_images_mobile/850_6428.jpg';
import Wildlife19_mobile from './wildlife_images_mobile/850_6556.jpg';
import Wildlife20_mobile from './wildlife_images_mobile/850_7549.jpg';
import Wildlife21_mobile from './wildlife_images_mobile/850_7552.jpg';
import Wildlife22_mobile from './wildlife_images_mobile/850_7706.jpg';
import Wildlife23_mobile from './wildlife_images_mobile/850_7723.jpg';
import Wildlife24_mobile from './wildlife_images_mobile/850_7727.jpg';
import Wildlife25_mobile from './wildlife_images_mobile/850_7736.jpg';
import Wildlife26_mobile from './wildlife_images_mobile/850_7755.jpg';
import Wildlife27_mobile from './wildlife_images_mobile/850_7805.jpg';
import Wildlife28_mobile from './wildlife_images_mobile/850_7853.jpg';
import Wildlife29_mobile from './wildlife_images_mobile/850_8846.jpg';
import Wildlife30_mobile from './wildlife_images_mobile/850_9810.jpg';
import Wildlife31_mobile from './wildlife_images_mobile/P1040747.JPG';
import Wildlife32_mobile from './wildlife_images_mobile/P1040784.JPG';

export const WildlifeImages_mobile = [
  {
    title: 'wildlife-image',
    image: Wildlife1_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'wildlife-image',
    image: Wildlife2_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'wildlife-image',
    image: Wildlife3_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife4_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife5_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife6_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife7_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife8_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife10_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife11_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife12_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife13_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife14_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife15_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife16_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife17_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife18_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife19_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife20_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife21_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife22_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife23_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife24_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife25_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife26_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife27_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife28_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife29_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife30_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife31_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife32_mobile,
    cName: 'sm-image-category'

  },
]

export const WildlifeImages = [
  {
    title: 'wildlife-image',
    image: Wildlife1,
    cName: 'sm-image-category',
  },
  {
    title: 'wildlife-image',
    image: Wildlife2,
    cName: 'sm-image-category',
  },
  {
    title: 'wildlife-image',
    image: Wildlife3,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife4,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife5,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife6,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife7,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife8,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife10,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife11,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife12,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife13,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife14,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife15,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife16,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife17,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife18,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife19,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife20,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife21,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife22,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife23,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife24,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife25,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife26,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife27,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife28,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife29,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife30,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife31,
    cName: 'sm-image-category'

  },
  {
    title: 'wildlife-image',
    image: Wildlife32,
    cName: 'sm-image-category'

  },
]
