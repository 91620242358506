import copa1 from './850_1241.jpg';
import copa2 from './850_1364.jpg';
import copa3 from './850_1572.jpg';
import copa4 from './850_1612.jpg';
import copa5 from './850_1337.jpg';
import copa6 from './850_1418.jpg';
import copa7 from './850_1495.jpg';
import copa8 from './850_1776.jpg';

export const projectCopaImages_mobile = [
  {
    title: 'copacobana',
    image: copa1,
    cName: 'sm-image-category',
  },
  {
    title: 'copacobana',
    image: copa2,
    cName: 'sm-image-category',
  },
  {
    title: 'copacobana',
    image: copa3,
    cName: 'sm-image-category',
  },
  {
    title: 'copacobana',
    image: copa4,
    cName: 'sm-image-category',
  },
  {
    title: 'copacobana',
    image: copa5,
    cName: 'sm-image-category',
  },
  {
    title: 'copacobana',
    image: copa6,
    cName: 'sm-image-category',
  },
  {
    title: 'copacobana',
    image: copa7,
    cName: 'sm-image-category',
  },
  {
    title: 'copacobana',
    image: copa8,
    cName: 'sm-image-category',
  },
]
