import React, { Component } from 'react';
import { BsChevronDown } from 'react-icons/bs'

class Gallery extends Component {

  render(){

    return(
      <div id='gallery'>

        <div className='border'>
          <div className='banner'>
            <div className='banner-innerContainer'>
                <div className='revealBorder'></div>
                <h1>Mijn galerij</h1>
                <div className='revealBorder'></div>
                <p>SCROLL</p>
                <BsChevronDown/> 
            </div>
          </div>

          <div className='reveal gallery-category gallery-portrait'>
              <div className='reveal gallery-innerContainer'>
                <div>
                  <h1>Portret</h1>
                  <p>Ieder portret dat met gevoel gefotografeerd wordt, is een portret die geluk brengt bij degene die geposeerd heeft. Niets geeft een beter gevoel
                    dan die personen gelukkig te maken. 
                  </p>
                    <a href='/Portrait'><button>GALERIJ PORTRET</button></a>
                </div>
              </div>
          </div>

          <div className='reveal gallery-category gallery-nature'>
              <div className='reveal gallery-innerContainer'>
                <div>
                  <h1>Natuur</h1>
                  <p>Wanneer woorden onduidelijk worden, zal ik me concentreren op foto's. Wanneer beelden ontoereikend worden, zal ik genoegen nemen met de stilte en de pracht van de natuur.</p>
                    <a href='/Nature'><button>GALERIJ NATUUR</button></a>
                </div>
              </div>
          </div>

          <div className='reveal gallery-category gallery-wildlife'>
              <div className='reveal gallery-innerContainer'>
                <div>
                  <h1>Wildlife</h1>
                  <p>Natuur zonder wildlife is gewoon landschap. No life without wildlife.</p>
                    <a href='/Wildlife'><button>GALERIJ WILDLIFE</button></a>
                </div>
              </div>
          </div>

          <div className='reveal gallery-category gallery-macro'>
              <div className='reveal gallery-innerContainer'>
                <div>
                  <h1>Macro</h1>
                  <p>Zelf de kleinste dingen in de natuur kunnen heel mooi zijn.</p>
                    <a href='/Macro'><button>GALERIJ MACRO</button></a>
                </div>
              </div>
          </div>

          <div className='reveal gallery-category gallery-citiesandstreets'>
              <div className='reveal gallery-innerContainer'>
                <div>
                  <h1>Steden & Straten</h1>
                  <p>Het is prettig om in een stad te lopen en te genieten van de architectuur. Steden en straten zijn maar zwarte puntjes op een kaart,
                     desondanks magisch om in persoon te bezichtigen.</p>
                    <a href='/CitiesAndStreets'><button>GALERIJ STEDEN & STRATEN</button></a>
                </div>
              </div>
          </div>

        </div>
      </div>
    )
  }
}

export default Gallery
