import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import{ init } from '@emailjs/browser';

init("YaBxGBD7hn002hPk6");


export const ContactUs = () => {
  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_1wozk9s', 'template_krbmsf8', form.current, 'YaBxGBD7hn002hPk6')
      .then((result) => {
          console.log(result.text);
          document.getElementById('formName').value = "";
          document.getElementById('formMail').value = "";
          document.getElementById('subject').value = "";
          document.getElementById('message').style.color = "green";

          setTimeout(function(){
            document.getElementById("message").style.color = 'white';
          }, 3000);

      }, (error) => {
          console.log(error.text);
      }); 
  };

  return (
    <form ref={form} onSubmit={sendEmail} id='myform'>
      <p id='message'>Uw bericht is succesvol verzonden!</p>
      <input id='formName' type="text" name="name" placeholder='Naam' required/>
      <input id='formMail' type="email" name="email" placeholder='Email' required/>
      <textarea id='subject' name="subject" placeholder='Bericht'/>
      <input type="submit" value="Verzenden"  id='submit'/>
    </form>
  );
};