import c1 from './850_0109.jpg'
import c2 from './850_0216.jpg'
import c3 from './850_0256.jpg'
import c4 from './850_0325.jpg'
import c5 from './850_0355.jpg'
import c6 from './850_0419.jpg'
import c7 from './850_0479.jpg'
import c8 from './850_0628.jpg'
import c9 from './850_0632.jpg'




export const projectCommunieImages_mobile = [
  {
    title: 'c',
    image: c1,
    cName: 'sm-image-category',
  },
  {
    title: 'c',
    image: c2,
    cName: 'sm-image-category',
  },
  {
    title: 'c',
    image: c3,
    cName: 'sm-image-category',
  },
  {
    title: 'c',
    image: c4,
    cName: 'sm-image-category',
  },
  {
    title: 'c',
    image: c5,
    cName: 'sm-image-category',
  },
  {
    title: 'c',
    image: c6,
    cName: 'sm-image-category',
  },
  {
    title: 'c',
    image: c7,
    cName: 'sm-image-category',
  },
  {
    title: 'c',
    image: c8,
    cName: 'sm-image-category',
  },
  {
    title: 'c',
    image: c9,
    cName: 'sm-image-category',
  },

]
