import Macro1 from './Macro_images/850_2553.jpg';
import Macro2 from './Macro_images/850_3650.jpg';
import Macro3 from './Macro_images/850_4051.jpg';
import Macro4 from './Macro_images/850_4541.jpg';
import Macro5 from './Macro_images/850_4565.jpg';
import Macro6 from './Macro_images/850_4579.jpg';
import Macro7 from './Macro_images/850_8334-HDR.jpg';
import Macro8 from './Macro_images/850_8969.jpg';
import Macro9 from './Macro_images/850_9072.jpg';
import Macro10 from './Macro_images/850_9095.jpg';
import Macro11 from './Macro_images/pad_lukas_vanhulle_02-bewerkt.jpg';
import Macro12 from './Macro_images/pad_lukas_vanhulle_05-bewerkt.jpg';
import Macro13 from './Macro_images/pad_lukas_vanhulle_08-bewerkt.jpg';
import Macro14 from './Macro_images/pad_lukas_vanhulle_09-bewerkt.jpg';
import Macro15 from './Macro_images/pad_lukas_vanhulle_10-bewerkt.jpg';

// MOBILE
import Macro1_mobile from './Macro_images_mobile/850_2553.jpg';
import Macro2_mobile from './Macro_images_mobile/850_3650.jpg';
import Macro3_mobile from './Macro_images_mobile/850_4051.jpg';
import Macro4_mobile from './Macro_images_mobile/850_4541.jpg';
import Macro5_mobile from './Macro_images_mobile/850_4565.jpg';
import Macro6_mobile from './Macro_images_mobile/850_4579.jpg';
import Macro7_mobile from './Macro_images_mobile/850_8334-HDR.jpg';
import Macro8_mobile from './Macro_images_mobile/850_8969.jpg';
import Macro9_mobile from './Macro_images_mobile/850_9072.jpg';
import Macro10_mobile from './Macro_images_mobile/850_9095.jpg';
import Macro11_mobile from './Macro_images_mobile/pad_lukas_vanhulle_02-bewerkt.jpg';
import Macro12_mobile from './Macro_images_mobile/pad_lukas_vanhulle_05-bewerkt.jpg';
import Macro13_mobile from './Macro_images_mobile/pad_lukas_vanhulle_08-bewerkt.jpg';
import Macro14_mobile from './Macro_images_mobile/pad_lukas_vanhulle_09-bewerkt.jpg';
import Macro15_mobile from './Macro_images_mobile/pad_lukas_vanhulle_10-bewerkt.jpg';



export const Macro_images_mobile = [
  {
    title: 'Macro-image',
    image: Macro1_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'Macro-image',
    image: Macro3_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro4_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro5_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro7_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro2_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro6_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro7_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'Macro-image',
    image: Macro8_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro9_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro10_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro11_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro12_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro13_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro14_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro15_mobile,
    cName: 'sm-image-category'

  },
]

export const MacroImages = [
  {
    title: 'Macro-image',
    image: Macro1,
    cName: 'sm-image-category',
  },
  {
    title: 'Macro-image',
    image: Macro3,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro4,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro5,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro7,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro2,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro6,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro7,
    cName: 'sm-image-category',
  },
  {
    title: 'Macro-image',
    image: Macro8,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro9,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro10,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro11,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro12,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro13,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro14,
    cName: 'sm-image-category'

  },
  {
    title: 'Macro-image',
    image: Macro15,
    cName: 'sm-image-category'

  },
]
