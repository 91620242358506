import Portrait1 from './Portrait_Images/850_0109.jpg';
import Portrait2 from './Portrait_Images/850_0158.jpg';
import Portrait3 from './Portrait_Images/850_0192.jpg';
import Portrait4 from './Portrait_Images/850_0216.jpg';
import Portrait5 from './Portrait_Images/850_0256.jpg';
import Portrait6 from './Portrait_Images/850_0299.jpg';
import Portrait7 from './Portrait_Images/850_0355.jpg';
import Portrait8 from './Portrait_Images/850_0360.jpg';
import Portrait9 from './Portrait_Images/850_0479.jpg';
import Portrait10 from './Portrait_Images/850_0628.jpg';
import Portrait11 from './Portrait_Images/850_0632.jpg';
import Portrait12 from './Portrait_Images/850_0691.jpg';
import Portrait13 from './Portrait_Images/850_0712.jpg';
import Portrait14 from './Portrait_Images/850_0753.jpg';
import Portrait15 from './Portrait_Images/850_1241.jpg';
import Portrait16 from './Portrait_Images/850_1262.jpg';
import Portrait17 from './Portrait_Images/850_1337.jpg';
import Portrait18 from './Portrait_Images/850_1364.jpg';
import Portrait19 from './Portrait_Images/850_1572.jpg';
import Portrait20 from './Portrait_Images/850_3085.jpg';
import Portrait21 from './Portrait_Images/850_3092.jpg';
import Portrait22 from './Portrait_Images/850_3126.jpg';
import Portrait23 from './Portrait_Images/850_3167.jpg';
import Portrait24 from './Portrait_Images/850_3223.jpg';
import Portrait25 from './Portrait_Images/850_3240.jpg';
import Portrait26 from './Portrait_Images/850_3250.jpg';
import Portrait27 from './Portrait_Images/850_3519.jpg';
import Portrait28 from './Portrait_Images/850_3574.jpg';
import Portrait29 from './Portrait_Images/850_3620.jpg'
import Portrait30 from './Portrait_Images/850_3723.jpg';
import Portrait31 from './Portrait_Images/850_3859.jpg';
import Portrait32 from './Portrait_Images/850_3925.jpg';
import Portrait33 from './Portrait_Images/850_3964.jpg';
import Portrait34 from './Portrait_Images/850_4242.jpg';
import Portrait35 from './Portrait_Images/850_4242.jpg';
import Portrait36 from './Portrait_Images/850_4339.jpg';
import Portrait37 from './Portrait_Images/850_4361.jpg';
import Portrait38 from './Portrait_Images/850_4446.jpg';
import Portrait39 from './Portrait_Images/850_4478.jpg';
import Portrait40 from './Portrait_Images/850_5275.jpg'
import Portrait41 from './Portrait_Images/850_5277.jpg';
import Portrait42 from './Portrait_Images/850_8210.jpg';
import Portrait43 from './Portrait_Images/850_8222.jpg';
import Portrait44 from './Portrait_Images/_1120290-2.jpg';
import Portrait45 from './Portrait_Images/_1120325-2.jpg';

// MOBILE
import Portrait1_mobile from './Portrait_images_mobile/850_0109.jpg';
import Portrait2_mobile from './Portrait_images_mobile/850_0158.jpg';
import Portrait3_mobile from './Portrait_images_mobile/850_0192.jpg';
import Portrait4_mobile from './Portrait_images_mobile/850_0216.jpg';
import Portrait5_mobile from './Portrait_images_mobile/850_0256.jpg';
import Portrait6_mobile from './Portrait_images_mobile/850_0299.jpg';
import Portrait7_mobile from './Portrait_images_mobile/850_0355.jpg';
import Portrait8_mobile from './Portrait_images_mobile/850_0360.jpg';
import Portrait9_mobile from './Portrait_images_mobile/850_0479.jpg';
import Portrait10_mobile from './Portrait_images_mobile/850_0628.jpg';
import Portrait11_mobile from './Portrait_images_mobile/850_0632.jpg';
import Portrait12_mobile from './Portrait_images_mobile/850_0691.jpg';
import Portrait13_mobile from './Portrait_images_mobile/850_0712.jpg';
import Portrait14_mobile from './Portrait_images_mobile/850_0753.jpg';
import Portrait15_mobile from './Portrait_images_mobile/850_1241.jpg';
import Portrait16_mobile from './Portrait_images_mobile/850_1262.jpg';
import Portrait17_mobile from './Portrait_images_mobile/850_1337.jpg';
import Portrait18_mobile from './Portrait_images_mobile/850_1364.jpg';
import Portrait19_mobile from './Portrait_images_mobile/850_1572.jpg';
import Portrait20_mobile from './Portrait_images_mobile/850_3085.jpg';
import Portrait21_mobile from './Portrait_images_mobile/850_3092.jpg';
import Portrait22_mobile from './Portrait_images_mobile/850_3126.jpg';
import Portrait23_mobile from './Portrait_images_mobile/850_3167.jpg';
import Portrait24_mobile from './Portrait_images_mobile/850_3223.jpg';
import Portrait25_mobile from './Portrait_images_mobile/850_3240.jpg';
import Portrait26_mobile from './Portrait_images_mobile/850_3250.jpg';
import Portrait27_mobile from './Portrait_images_mobile/850_3519.jpg';
import Portrait28_mobile from './Portrait_images_mobile/850_3574.jpg';
import Portrait29_mobile from './Portrait_images_mobile/850_3620.jpg'
import Portrait30_mobile from './Portrait_images_mobile/850_3723.jpg';
import Portrait31_mobile from './Portrait_images_mobile/850_3859.jpg';
import Portrait32_mobile from './Portrait_images_mobile/850_3925.jpg';
import Portrait33_mobile from './Portrait_images_mobile/850_3964.jpg';
import Portrait34_mobile from './Portrait_images_mobile/850_4242.jpg';
import Portrait35_mobile from './Portrait_images_mobile/850_4242.jpg';
import Portrait36_mobile from './Portrait_images_mobile/850_4339.jpg';
import Portrait37_mobile from './Portrait_images_mobile/850_4361.jpg';
import Portrait38_mobile from './Portrait_images_mobile/850_4446.jpg';
import Portrait39_mobile from './Portrait_images_mobile/850_4478.jpg';
import Portrait40_mobile from './Portrait_images_mobile/850_5275.jpg'
import Portrait41_mobile from './Portrait_images_mobile/850_5277.jpg';
import Portrait42_mobile from './Portrait_images_mobile/850_8210.jpg';
import Portrait43_mobile from './Portrait_images_mobile/850_8222.jpg';
import Portrait44_mobile from './Portrait_images_mobile/_1120290-2.jpg';
import Portrait45_mobile from './Portrait_images_mobile/_1120325-2.jpg';



export const PortraitImages_mobile = [
  {
    title: 'Portrait-image',
    image: Portrait25_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait26_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait27_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait43_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait36_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait37_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait1_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'Portrait-image',
    image: Portrait2_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'Portrait-image',
    image: Portrait3_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait4_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait5_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait6_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait7_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait8_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait9_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait10_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait11_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait12_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait13_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait14_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait15_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait16_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait17_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait18_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait19_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait20_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait21_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait22_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait23_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait24_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait28_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait29_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait30_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait31_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait32_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait33_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait34_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait35_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait38_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait39_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait40_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait41_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait42_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait44_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait45_mobile,
    cName: 'sm-image-category'

  },
]

export const PortraitImages = [
  {
    title: 'Portrait-image',
    image: Portrait25,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait26,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait27,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait43,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait36,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait37,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait1,
    cName: 'sm-image-category',
  },
  {
    title: 'Portrait-image',
    image: Portrait2,
    cName: 'sm-image-category',
  },
  {
    title: 'Portrait-image',
    image: Portrait3,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait4,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait5,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait6,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait7,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait8,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait9,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait10,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait11,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait12,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait13,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait14,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait15,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait16,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait17,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait18,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait19,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait20,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait21,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait22,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait23,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait24,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait28,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait29,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait30,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait31,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait32,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait33,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait34,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait35,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait38,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait39,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait40,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait41,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait42,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait44,
    cName: 'sm-image-category'

  },
  {
    title: 'Portrait-image',
    image: Portrait45,
    cName: 'sm-image-category'

  }, 
]



