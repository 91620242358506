import React, { Component } from 'react';
import { PortraitImages, PortraitImages_mobile } from './PortraitImages';
import Category from '../Category';

class Wildlife extends Component {
  render(){
    return(
      <div className='border category'>
        <div id='portrait' className='categoryBanner gallery-portrait'></div>
        <h1>Portret</h1>
        <Category images={window.innerWidth > 640 ? PortraitImages : PortraitImages_mobile}/>
      </div>
    )
  }
}

export default Wildlife;