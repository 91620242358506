import React from 'react';  
import { ContactUs } from '../../pages/contact/ContactUs';
import contactPhoto from '../../pages/assets/contactHome.jpg';

import './Modal.css';

class Popup extends React.Component {  
  render() {  
return (  
<div className='popup' id='popup'>  
<div className='popup_open'>  
<button onClick={this.props.closePopup}>X</button>  
<h1>Contact me</h1> 
<img src={contactPhoto} alt='Lukas Van Hulle'></img> 
<ContactUs />
</div>  
</div>  
);  
}  
}  
export default Popup;