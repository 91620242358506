import React, { Component } from 'react';
import { GrZoomIn } from 'react-icons/gr';

class Category extends Component {


  handleClick = item => {
      var modal = document.getElementById("myModal");
      var modalImg = document.getElementById("img01");
      modal.style.display = "block";
      modalImg.src = item.image;
  }
  
  handleClose = () => {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  }

  render(){

    return(
      <div> 
        <div className='images'>
        {this.props.images.map((item, index) => {
          return(
            <div className='img-wrapper'>
              <div key={index} className={item.cName}>
                <img src={item.image} alt={item.title} onClick={() => this.handleClick(item)}></img>
                <div id="myModal" className="modal">
                  <span className="close" onClick={this.handleClose}>&times;</span>
                  <img className="modal-content" id="img01" src={item.image} alt={item.title}></img>
                  <div id="caption"></div>
                </div>
              </div>
              <div className='image-icons'>
                <div>
                  <GrZoomIn/> 
                </div>
              </div>
            </div>
          )
        })}
        </div>
      </div>
    )
  }
}

export default Category
