import React, { Component } from 'react';

import { ContactUs } from './ContactUs';
import contactHome from '../assets/contactHome.jpg';
import signature from './signature_color.png';


// function for changing the color of the nav menu
function changeColor() {
  if(window.location.pathname === '/Contact'){
    document.getElementById('logo').getElementsByTagName('img')[0].src = signature;
    var spanElements = document.getElementsByTagName('span');

    for (var i = 0; i < spanElements.length; i++) {
        spanElements[i].style.background = '#051c23';
    }

        var cols = document.getElementById('navbar').getElementsByTagName('a');
    for(let i=0; i < cols.length; i++) {
      cols[i].style.color = '#051c23';
    }
  }
}

class Contact extends Component {
  render(){
    return(
      <div id='contact' onLoad={changeColor}>
        <div>
          <img src={contactHome} alt='contactHome'></img>
        </div>
        <div>
          <h1>Contact</h1>
          <ContactUs />
        </div>
      </div>
    )
  }
}

export default Contact