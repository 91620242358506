import React, { Component } from 'react'
import werkaandemuur1 from '../assets/shop/werkaandemuur1.jpg'
import werkaandemuur2 from '../assets/shop/werkaandemuur2.jpg'
import werkaandemuur3 from '../assets/shop/werkaandemuur3.jpg'
import werkaandemuur4 from '../assets/shop/werkaandemuur4.jpg'
import signature from './signature_color.png'


// function for changing the color of the nav menu
function changeColor() {
  if(window.location.pathname === '/Shop'){
    document.getElementById('logo').getElementsByTagName('img')[0].src = signature;
    var spanElements = document.getElementsByTagName('span');

    for (var i = 0; i < spanElements.length; i++) {
        spanElements[i].style.background = '#051c23';
    }

        var cols = document.getElementById('navbar').getElementsByTagName('a');
    for(let i=0; i < cols.length; i++) {
      cols[i].style.color = '#051c23';
    }
  }
}


class Shop extends Component {
  render(){
    return(
      <div className='border' id='shop' onLoad={changeColor}>
        <div className='articles'>
          <div className='article'>
            <img src={werkaandemuur1}></img>
          </div>
          <div className='article'>
            <img src={werkaandemuur2}></img>
          </div>
          <div className='article'>
            <img src={werkaandemuur3}></img>
          </div>
          <div className='article'>
            <img src={werkaandemuur4}></img>
          </div>
        </div>
        <div className='werkaandemuur'>
          <div>
          <h1>werkaandemuur</h1>
          <p>Wil jij één van mijn werken aan jouw muur? Kijk dan zeker eens naar mijn <a href='https://www.werkaandemuur.nl/nl/shop/lukas-van-hulle/131940'>werkaandemuur profiel</a></p>
          </div>
        </div>
      </div>
    )
  }
}

export default Shop