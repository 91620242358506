import React, { Component } from "react";
import aboutHomeImage from "../assets/aboutHome.JPG";
import cameraIcon from "../assets/biography/camera.png";
import lensIcon from "../assets/biography/zoom-lens.png";
import statiefIcon from "../assets/biography/tripod.png";
import rugzakIcon from "../assets/biography/backpack.png";

class Biography extends Component {
  render() {
    return (
      <div id="biography">
        <div className="border">
          <div className="banner">
            <div id="upper-image">
              <img src={aboutHomeImage} alt="Lukas Van Hulle"></img>
            </div>
            <div>
              <h1>OVER MIJ</h1>
              <p>
                Hallo, ik ben Lukas, een 22- jarige fotograaf met een extreme
                passie voor natuur en wildlife fotografie. Ik hou ervan om s’
                morgens vroeg al in de natuur te zijn. Ik geniet van de rust en
                sta telkens weer verbaasd van haar schoonheid. Je zou ervan
                versteld staan van wat je zo vroeg al te zien krijgt zoals bv
                een kleurrijke zonsopkomst met een dik deken van mist over een
                uitgestrekt landschap of een mooi reetje dat op een paar meters
                van jou voorbijloopt. Daar geniet ik echt van! Het startte voor
                mij rond de leeftijd van 17. Telkens wanneer ik iets mooi vond,
                legde ik dit vast met mijn gsm om het achteraf, voorzien van een
                bijpassend liedje, te tonen aan vrienden en familie. In 2019
                kocht ik mijn eerste camera: een Lumix G7 met twee kitlenzen. Al
                snel merkte ik dat je met zo veel meer rekening moet houden
                zoals diafragma en sluitertijd instellingen. Het interesseerde
                mij echt om daar meer over te gaan bijleren en schreef mij in
                voor mijn eerste cursus in het Kisp Gent. Ik studeer momenteel
                nog steeds fotografie in avondonderwijs om nog meer technieken
                onder de knie te krijgen met als doel om uiteindelijk mijn
                diploma te kunnen behalen van professioneel fotograaf. Mijn
                fotogalerij geeft een overzicht van de fotoreportages die ik tot
                nu toe al maakte. Ik nodig jullie uit om een kijkje te nemen!
              </p>
              <div className="uitrusting">
                <div className="uitrustingItem">
                  <img src={cameraIcon}></img>
                  <p>Nikon D850 body</p>
                </div>
                <div className="uitrustingItem">
                  <img src={lensIcon}></img>
                  <p>
                    tamron 150-600mm G2 F/5-6.3 <br></br>tamron 24-70mm G2 F/2.8{" "}
                    <br></br>Nikon 105mm 1:2.8 VR micro
                  </p>
                </div>
                <div className="uitrustingItem">
                  <img src={statiefIcon}></img>
                  <p>leofoto RF-324C</p>
                </div>
                <div className="uitrustingItem">
                  <img src={rugzakIcon}></img>
                  <p>Protactic BP 450 AW II</p>
                </div>
              </div>
              <div id="info">
                +32 471 65 03 15 <br></br>
                Photography.vanhulle@gmail.com <br></br>
                Gevestigd in Gent, België <br></br>
                &copy;Mijn foto’s zijn auteursrechtelijk beschermd, niet
                gebruiken zonder expliciete toestemming.
              </div>
            </div>
            <div id="bottom-image">
              <img src={aboutHomeImage} alt="Lukas Van Hulle"></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Biography;
