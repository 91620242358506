import React, { Component } from 'react';
import { MacroImages, Macro_images_mobile } from './MacroImages';
import Category from '../Category';


class Wildlife extends Component {
  render(){
    return(
      <div className='border category'>
        <div id='macro' className='categoryBanner gallery-macro'></div>
        <h1>Macro</h1>
        <Category images={window.innerWidth > 640 ? MacroImages : Macro_images_mobile}/>
      </div>
    )
  }
}

export default Wildlife;