import React, { Component } from 'react';
import { WildlifeImages_mobile, WildlifeImages } from './WildlifeImages';
import Category from '../Category';

class Wildlife extends Component {



  render(){
    return(
      <div className='border category'>
        <div className='categoryBanner gallery-wildlife'></div>
        <h1>Wildlife</h1>
        <Category images={window.innerWidth > 640 ? WildlifeImages : WildlifeImages_mobile}/>
      </div>
    )
  }
}

export default Wildlife;