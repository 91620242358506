import React, { Component } from 'react';
import { NatureImages, NatureImages_mobile } from './NatureImages';
import Category from '../Category';

class Nature extends Component {
  render(){
    return(
      <div className='border category'>
        <div id='nature' className='categoryBanner gallery-nature'></div>
        <h1>Natuur</h1>
        <Category images={window.innerWidth > 640 ? NatureImages : NatureImages_mobile }/>
      </div>
    )
  }
}

export default Nature;