import Nature1 from './Nature_images/850_0057.jpg';
import Nature2 from './Nature_images/850_0106-HDR.jpg';
import Nature3 from './Nature_images/850_0492.jpg';
import Nature4 from './Nature_images/850_1467.jpg';
import Nature5 from './Nature_images/850_2183.jpg';
import Nature6 from './Nature_images/850_2268.jpg';
import Nature7 from './Nature_images/850_2288.jpg';
import Nature8 from './Nature_images/850_2338.jpg';
import Nature9 from './Nature_images/850_2482-HDR.jpg';
import Nature10 from './Nature_images/850_2523-HDR.jpg';
import Nature11 from './Nature_images/850_2533-HDR.jpg';
import Nature12 from './Nature_images/850_2699-HDR.jpg';
import Nature13 from './Nature_images/850_2738.jpg';
import Nature14 from './Nature_images/850_4704.jpg';
import Nature15 from './Nature_images/850_5295.jpg';
import Nature16 from './Nature_images/850_5350.jpg';
import Nature18 from './Nature_images/850_5491.jpg';
import Nature19 from './Nature_images/850_5505.jpg';
import Nature20 from './Nature_images/850_7441-HDR.jpg';
import Nature21 from './Nature_images/850_7865-HDR.jpg';
import Nature22 from './Nature_images/850_7905-HDR.jpg';
import Nature23 from './Nature_images/850_8901-HDR.jpg';
import Nature24 from './Nature_images/P1040410-01.jpg';

//MOBILE
import Nature1_mobile from './Nature_images_mobile/850_0057.jpg';
import Nature2_mobile from './Nature_images_mobile/850_0106-HDR.jpg';
import Nature3_mobile from './Nature_images_mobile/850_0492.jpg';
import Nature4_mobile from './Nature_images_mobile/850_1467.jpg';
import Nature5_mobile from './Nature_images_mobile/850_2183.jpg';
import Nature6_mobile from './Nature_images_mobile/850_2268.jpg';
import Nature7_mobile from './Nature_images_mobile/850_2288.jpg';
import Nature8_mobile from './Nature_images_mobile/850_2338.jpg';
import Nature9_mobile from './Nature_images_mobile/850_2482-HDR.jpg';
import Nature10_mobile from './Nature_images_mobile/850_2523-HDR.jpg';
import Nature11_mobile from './Nature_images_mobile/850_2533-HDR.jpg';
import Nature12_mobile from './Nature_images_mobile/850_2699-HDR.jpg';
import Nature13_mobile from './Nature_images_mobile/850_2738.jpg';
import Nature14_mobile from './Nature_images_mobile/850_4704.jpg';
import Nature15_mobile from './Nature_images_mobile/850_5295.jpg';
import Nature16_mobile from './Nature_images_mobile/850_5350.jpg';
import Nature18_mobile from './Nature_images_mobile/850_5491.jpg';
import Nature19_mobile from './Nature_images_mobile/850_5505.jpg';
import Nature20_mobile from './Nature_images_mobile/850_7441-HDR.jpg';
import Nature21_mobile from './Nature_images_mobile/850_7865-HDR.jpg';
import Nature22_mobile from './Nature_images_mobile/850_7905-HDR.jpg';
import Nature23_mobile from './Nature_images_mobile/850_8901-HDR.jpg';
import Nature24_mobile from './Nature_images_mobile/P1040410-01.jpg';


export const NatureImages_mobile = [
  {
    title: 'Nature1',
    image: Nature1_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'Nature-image',
    image: Nature2_mobile,
    cName: 'sm-image-category',
  },
  {
    title: 'Nature-image',
    image: Nature3_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature4_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature5_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature6_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature7_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature8_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature9_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature10_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature11_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature12_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature13_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature14_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature15_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature16_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature18_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature19_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature20_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature21_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature22_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature23_mobile,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature24_mobile,
    cName: 'sm-image-category'

  },
]


export const NatureImages = [
  {
    title: 'Nature1',
    image: Nature1,
    cName: 'sm-image-category',
  },
  {
    title: 'Nature-image',
    image: Nature2,
    cName: 'sm-image-category',
  },
  {
    title: 'Nature-image',
    image: Nature3,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature4,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature5,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature6,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature7,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature8,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature9,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature10,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature11,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature12,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature13,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature14,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature15,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature16,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature18,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature19,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature20,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature21,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature22,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature23,
    cName: 'sm-image-category'

  },
  {
    title: 'Nature-image',
    image: Nature24,
    cName: 'sm-image-category'

  },
]

