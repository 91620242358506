import React, { Component } from 'react';
import { BsFacebook, BsInstagram } from 'react-icons/bs';
import signature from './signature.png';
import signature_color from '../../pages/contact/signature_color.png';


import './Navbar.css';

class Navbar extends Component {

  state = { clicked: false }

  handleClick = () => {
    this.toggleIcon();
    this.setState({clicked : !this.state.clicked})
  }

  toggleIcon(){
    if ( document.getElementById('hamburger-icon').className !== 'open' ) {
      document.getElementById('hamburger-icon').className += 'open';
      document.getElementById('logoImg').src = signature_color;
    }
    else {
      document.getElementById('hamburger-icon').className = '';
      document.getElementById('logoImg').src = signature;
    }
  }

  render(){
    return(
      <nav className={this.state.clicked ? 'navbar active' : 'navbar'} id='navbar'>
        
        <a href='/' className='logo' id='logo'>
          <img id='logoImg' src={signature} alt='signature'>
          </img>
        </a>

        <div id="hamburger-icon" onClick={this.handleClick}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'}>
          <li>
            <a href='/Gallery' className='nav-link'>
              Galerij
            </a>
          </li>
          <li>
            <a href='/Biography' className='nav-link'>
              Biografie
            </a>
          </li>
          <li>
            <a href='/Shop' className='nav-link'>
              Winkel
            </a>
          </li>
          <li>
            <a href='/Contact' className='nav-link'>
              Contact
            </a>
          </li>

          <ul className='socialmedia'>
            <li>
              <a href='https://www.facebook.com/photographyVanhulle'>
                <BsFacebook/>
              </a>
            </li>
            <li>
              <a href='https://www.instagram.com/photography_vanhulle/'>
                <BsInstagram/>
              </a>
            </li>
          </ul>
        </ul>
      </nav>
    )
  }

}

export default Navbar