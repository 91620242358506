import React, { Component } from "react";
// import Popup from 'reactjs-popup';
import { BsChevronDown, BsArrowRight } from "react-icons/bs";
import aboutHome from "../assets/aboutHome.JPG";
import Contact from "../contact/Contact";
import bannerCameraIcon from "../assets/bannerCameraIcon.png";

import "../pages.css";
import Projects from "./Projects";

class Home extends Component {
  render() {
    return (
      <div id="home">
        <div className="border">
          <div className="banner" id="banner">
            <div className="banner-innerContainer" id="banner-innerContainer">
              <img src={bannerCameraIcon} alt="bannerCameraIcon"></img>
              <div className="revealBorder"></div>
              <h1>Lukas Van Hulle Fotograaf</h1>
              <div className="revealBorder"></div>
              <p>SCROLL</p>
              <BsChevronDown />
            </div>
          </div>
        </div>

        <div id="about">
          <div>
            <img src={aboutHome} alt="aboutHome" className="reveal left"></img>
          </div>
          <div>
            <h1 className="title">Over mij</h1>
            <p>
              Hallo, ik ben Lukas, een 22- jarige fotograaf met een extreme
              passie voor natuur en wildlife fotografie. Ik hou ervan om s’
              morgens vroeg al in de natuur te zijn. Ik geniet van de rust en
              sta telkens weer verbaasd van haar schoonheid.
            </p>
            <a href="/Biography">
              Lees meer <BsArrowRight />
            </a>
          </div>
        </div>

        <Projects />
        <Contact />
      </div>
    );
  }
}

export default Home;
