import React, { Component } from 'react';  
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Biography from './pages/biography/Biography';
import Gallery from './pages/gallery/Gallery';
import Nature from './pages/gallery/Nature/Nature';
import Wildlife from './pages/gallery/Wildlife/Wildlife';
import Macro from './pages/gallery/Macro/Macro';
import Portrait from './pages/gallery/Portrait/Portrait';
import Cities from './pages/gallery/Cities&Streets/Cities';
import Shop from './pages/shop/Shop';

import Modal from './components/modal/Modal';

import './App.css';


// animation reveal
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

// animation reveal - banner border
function revealBorder() {
  var reveals = document.querySelectorAll(".revealBorder");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}




function popup(){
  if(localStorage.getItem('popState') !== 'shown'){
    setTimeout(function(){   
      localStorage.setItem('popState','shown');
       document.getElementById("popup").style.display = 'inherit';
    }, 15000);
  } 
}

// initialize animations
window.addEventListener("load", popup);
window.addEventListener("scroll", reveal);
window.addEventListener("load", revealBorder);


class App extends Component { 

  togglePopup(){
     document.getElementById('popup').style.display = 'none';
  }
    
  render(){
    return (
      <>
      <Router>
        <Navbar/>
        <Modal
           closePopup={this.togglePopup.bind(this)}  
        />  
        <Switch>
          <Route path='/' exact component={Home}></Route>
          <Route path='/Contact' exact component={Contact}></Route>
          <Route path='/Biography' exact component={Biography}></Route>
          <Route path='/Gallery' exact component={Gallery}></Route>
            <Route path='/Nature' exact component={Nature}></Route>
            <Route path='/Wildlife' exact component={Wildlife}></Route>
            <Route path='/Portrait' exact component={Portrait}></Route>
            <Route path='/Macro' exact component={Macro}></Route>
            <Route path='/CitiesAndStreets' exact component={Cities}></Route>
          <Route path='/Shop' exact component={Shop}></Route>
        </Switch>
        <Footer/>
      </Router>
      </>
    );
  }
}

export default App;
