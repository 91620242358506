import React, { Component } from 'react';
import { CitiesImages, CitiesImages_mobile } from './CitiesImages';
import Category from '../Category';


class Cities extends Component {
  render(){
    return(
      <div className='border category'>
        <div id='citiesandstreets' className='categoryBanner gallery-citiesandstreets'></div>
        <h1>Steden & Straten</h1>
        <Category images={window.innerWidth > 640 ? CitiesImages : CitiesImages_mobile}/>
      </div> 
    )
  }
}

export default Cities;