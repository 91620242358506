import dogPhoto1 from './850_7979.jpg';
import dogPhoto2 from './850_8003.jpg';
import dogPhoto3 from './850_8009.jpg';
import dogPhoto4 from './850_8104.jpg';
import dogPhoto6 from './850_8169.jpg';
import dogPhoto7 from './850_8189.jpg';
import dogPhoto8 from './850_8222.jpg';
import dogPhoto9 from './850_8277.jpg';






export const projectDogsImages = [
  {
    title: 'dogPhoto',
    image: dogPhoto1,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: dogPhoto2,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: dogPhoto3,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: dogPhoto4,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: dogPhoto6,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: dogPhoto7,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: dogPhoto8,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: dogPhoto9,
    cName: 'sm-image-category',
  },
]
