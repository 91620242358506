import sport1 from './850_0691.jpg';
import sport2 from './850_0712.jpg';
import sport3 from './850_0742.jpg';
import sport4 from './850_0753.jpg';
import sport5 from './850_0767.jpg';
import sport6 from './850_0716.jpg';



export const projectSportImages = [
  {
    title: 'dogPhoto',
    image: sport1,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: sport2,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: sport3,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: sport4,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: sport5,
    cName: 'sm-image-category',
  },
  {
    title: 'dogPhoto',
    image: sport6,
    cName: 'sm-image-category',
  },
]
