import React, { Component } from "react";
import { BsArrowRight } from "react-icons/bs";
import { projectDogsImages } from "../assets/projects/projectDogs/projectDogsImages";
import { projectCopaImages } from "../assets/projects/projectCopa/projectCopaImages";
import { projectSportImages } from "../assets/projects/projectSport/projectSportImages";
import { projectCommunieImages } from "../assets/projects/projectCommunie/projectCommunieImages";
import { projectDogsImages_mobile } from "../assets/projects/projectDogs_mobile/projectDogsImages";
import { projectCopaImages_mobile } from "../assets/projects/projectCopa_mobile/projectCopaImages";
import { projectSportImages_mobile } from "../assets/projects/projectSport_mobile/projectSportImages";
import { projectCommunieImages_mobile } from "../assets/projects/projectCommunie_mobile/projectCommunieImages";
import projectDogs from "../assets/projects/projectDogs.jpg";
import projectCopa from "../assets/projects/projectCopa.jpg";
import projectSport from "../assets/projects/projectFitness.jpg";
import projectCommunie from "../assets/projects/projectCommunie.jpg";

function handleSeeMore(project) {
  document.getElementById(project).className = "popup-project active";
}

function handleClose() {
  document.getElementById("dogShoot").className = "popup-project";
  document.getElementById("copacobana").className = "popup-project";
  document.getElementById("sport").className = "popup-project";
  document.getElementById("communie").className = "popup-project";
}

class Projects extends Component {
  widthState = window.innerWidth > 640;

  render() {
    return (
      <div id="projects">
        <div className="popup-project" id="dogShoot">
          <div className="images">
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            {this.widthState
              ? projectDogsImages.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={item.cName}>
                        <img src={item.image} alt="dogshoot"></img>
                      </div>
                    </div>
                  );
                })
              : projectDogsImages_mobile.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={item.cName}>
                        <img src={item.image} alt="dogshoot"></img>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <div className="popup-project" id="copacobana">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <div className="images">
            {this.widthState
              ? projectCopaImages.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={item.cName}>
                        <img src={item.image} alt="copacobana"></img>
                      </div>
                    </div>
                  );
                })
              : projectCopaImages_mobile.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={item.cName}>
                        <img src={item.image} alt="copacobana"></img>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <div className="popup-project" id="sport">
          <div className="images">
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            {this.widthState
              ? projectSportImages.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={item.cName}>
                        <img src={item.image} alt="sport"></img>
                      </div>
                    </div>
                  );
                })
              : projectSportImages_mobile.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={item.cName}>
                        <img src={item.image} alt="sport"></img>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <div className="popup-project" id="communie">
          <div className="images">
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            {this.widthState
              ? projectCommunieImages.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={item.cName}>
                        <img src={item.image} alt="sport"></img>
                      </div>
                    </div>
                  );
                })
              : projectCommunieImages_mobile.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={item.cName}>
                        <img src={item.image} alt="sport"></img>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <h1 className="title projectTitle">Projecten</h1>

        <div className="project reveal left">
          <img src={projectDogs} alt="Uba Theonesearchedfor"></img>
          <div>
            <div>
              <h2>Honden</h2>
              <p>
                Honden fotograferen is één van mijn projecten waarbij ik een zo
                goed mogelijk beeld wil vastleggen van het geliefde speelse
                huisdier. Zoals te zien bij deze Australische herder genaamd
                Uba. Als je wat toffe foto’s wil van je huisdier kan je mij
                altijd contacteren via <a href="#contact">CONTACT</a>.
                <span onClick={() => handleSeeMore("dogShoot")}>
                  See more <BsArrowRight />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="project reveal left">
          <img src={projectCopa} alt="copacobana"></img>
          <div>
            <div>
              <h2>Copacobana Festival</h2>
              <p>
                Buiten het heel graag luisteren naar muziek en naar
                concerten/festivals gaan met vrienden, vind ik het heel leuk om
                foto’s te kunnen maken van bv die ene band die zich volledig
                ‘smijt’ in hun liveoptreden om te kunnen tonen hoe goed zij dit
                kunnen en wat ze daarvoor over hebben. Om hiervan een beeld te
                kunnen maken dat dit ook zo weergeeft, vind ik machtig! Mijn
                doel is om uiteindelijk als fotograaf te mogen gaan naar een
                populair bekend optreden/festival.
                <span onClick={() => handleSeeMore("copacobana")}>
                  See more <BsArrowRight />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="project reveal left">
          <img src={projectCommunie} alt="Communie"></img>
          <div>
            <div>
              <h2>Communie</h2>
              <p>
                Onlangs de kans gekregen om communiefoto’s te maken van 2 lieve
                kindjes uit een mooi gezin. Een leuke uitdaging. Het was
                natuurlijk wel wat anders dan een doodgewone fotoshoot omdat het
                met kinderen was. Een goede voorbereiding, zoals het vinden van
                de juiste locatie, het voorzien van enkele attributen, is
                cruciaal om de fotoshoot vlot te laten verlopen. Ik wou
                natuurlijk ook genoeg weten over hoe ik ze juist op hun gemak
                zou kunnen stellen en hun te kunnen laten genieten van de leuke
                fotoshoot wat mij uiteindelijk ook goed gelukt is.
                <span onClick={() => handleSeeMore("communie")}>
                  See more <BsArrowRight />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="project reveal left">
          <img src={projectSport} alt="Sport"></img>
          <div>
            <div>
              <h2>Sport</h2>
              <p>
                Sporten is toch wel een wekelijkse bezigheid geworden zoals
                onder andere lopen of fitnessen om zo gezond mogelijk te
                blijven. Ik heb daarom ook veel appreciatie voor personen die
                daar heel veel tijd aan spenderen. Ik maak daarom ook graag
                foto’s van sporters, als daar vraag naar is, zoals hier te zien
                bij deze bodybuilder die traint om aan wedstrijden te kunnen
                deelnemen.
                <span onClick={() => handleSeeMore("sport")}>
                  See more <BsArrowRight />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
